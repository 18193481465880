import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../contexts/AuthContext";
import { getUpdatingUserObject } from "../features/states/userSlice";
const useCompletedCourses = () => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  useEffect(() => {
    dispatch(getUpdatingUserObject(currentUser?.uid));
  }, [dispatch]);

  const user = useSelector((state) => state.user.value);
  const { courses } = user;

  const completedCourses = useMemo(() => {
    const coursesArray = Object.values(courses);

    return coursesArray.filter(
      (course) => course?.completed && course?.courseId !== "tutorial"
    );
  }, [courses]);

  const getMetaDataOfCompletedCourse = (courseId) => {
    return completedCourses.find(
      (queriedCourse) => queriedCourse?.courseId === courseId
    );
  };

  return {
    completedCourses,
    getMetaDataOfCompletedCourse,
  };
};

export default useCompletedCourses;
