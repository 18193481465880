import { Grid, Paper } from "@mui/material";
import { React } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useAuth } from "../../contexts/AuthContext";
import { accountsTrialingAI } from "../../ai/accountsTrialingAI";
export default function HomeButtons({ handleOpenAIButton, userObject }) {
  const { currentUser } = useAuth();

  const Item = styled(Paper)(({ theme }) => ({
    boxShadow: "none",
    borderRadius: "20px",
    height: "250px",
    marginTop: "1em",
    display: "flex",
    flexDirection: "column",
    textDecoration: "none",
  }));

  return (
    <>
      <Grid
        container
        spacing={1}
        columns={{ xl: 3, sm: 1, m: 3, lg: 3, xl: 3 }}
      >
        <Grid item xs={4} xl={1} lg={1} s={1}>
          <Item component={Link} to={"/courses"}>
            <div className="btn pink" style={{ height: "250px" }}>
              <h2>Workshops & CEs</h2>
              <p>
                Free & unique education to take your clinical skills & business
                to the next level
              </p>
              <div
                style={{
                  position: "absolute",
                  right: "2em",
                  bottom: "2em",
                  fontWeight: "bold",
                }}
              >
                View Courses
              </div>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4} xl={1} lg={1} s={1}>
          <Item component={Link} to={"/resources"}>
            <div className="btn blue-circle" style={{ height: "250px" }}>
              <h2>Resources</h2>
              <p>
                A one stop hub for clinical resources plus easily share them
                with patients by email/text
              </p>
              <div
                style={{
                  position: "absolute",
                  right: "2em",
                  bottom: "2em",
                  fontWeight: "bold",
                }}
              >
                Open Resources
              </div>
            </div>
          </Item>
        </Grid>
        <Grid item xs={4} xl={1} lg={1} s={1}>
          {currentUser && (
            <>
              {currentUser?.email?.includes("@medessist.com") ||
              accountsTrialingAI.includes(currentUser?.uid) ? (
                <Item onClick={handleOpenAIButton}>
                  <div
                    className="btn yellow-circle"
                    style={{ height: "250px" }}
                  >
                    <h2>AI (In Beta)</h2>
                    <p>
                      A highly accurate clinical support assistant trained on
                      18,000+ clinical resources
                    </p>
                    <div
                      style={{
                        position: "absolute",
                        right: "2em",
                        bottom: "2em",
                        fontWeight: "bold",
                      }}
                    >
                      Ask a Question
                    </div>
                  </div>
                </Item>
              ) : (
                <Item
                  onClick={() =>
                    window.open(
                      userObject?.profession === "Pharmacist"
                        ? "https://medessist.com/ai"
                        : "https://medessist.com/np",
                      "_blank"
                    )
                  }
                >
                  <div
                    className="btn yellow-circle"
                    style={{ height: "250px" }}
                  >
                    <h2>AI (In Beta)</h2>
                    <p>
                      A highly accurate clinical support assistant trained on
                      18,000+ clinical resources
                    </p>
                    <div
                      style={{
                        position: "absolute",
                        right: "2em",
                        bottom: "2em",
                        fontWeight: "bold",
                      }}
                    >
                      Sign up for Beta
                    </div>
                  </div>
                </Item>
              )}
            </>
          )}
          {!currentUser && (
            <Item>
              <div
                className="btn"
                style={{ height: "250px", cursor: "default" }}
              >
                <h2>AI</h2>
                <p>Coming soon - sign up for our free beta today!</p>
                <div className="ai-button-wrapper">
                  <div className="ai-buttons">
                    <button
                      onClick={() =>
                        window.open("https://medessist.com/ai", "_blank")
                      }
                      className="new-button-style light-blue"
                    >
                      Pharmacist Sign up
                    </button>
                    <button
                      onClick={() =>
                        window.open("https://medessist.com/np", "_blank")
                      }
                      className="new-button-style light-blue"
                    >
                      NP Sign up
                    </button>
                  </div>
                </div>
              </div>
            </Item>
          )}
        </Grid>
      </Grid>
    </>
  );
}
