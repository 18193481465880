import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { updateDontShowPopUp } from "../components/helpers/source";
export default function InitialLinkingPharmacySetUpModal({
  open,
  setShowInitialLinkingPharmacySetUpModal,
  userObject,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => setShowInitialLinkingPharmacySetUpModal(false)}
    >
      <DialogTitle>Congratulations on creating your account!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Welcome to MedEssist Hub – your ultimate practice companion, equipped
          with education, clinical resources, and AI support!
          <br />
          <br />
          For HCP Authentication, return to your MedEssist dashboard, click HCP
          Log in, and use your MedEssist Hub email to link your healthcare site.
          Your hub sign up email is: {userObject?.email}
          <br />
          <br />
          Otherwise, head to the Workshops and CEs section and check out the
          tutorial to make the most out of your account.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            updateDontShowPopUp(userObject?.uid);
            setShowInitialLinkingPharmacySetUpModal(false);
          }}
        >
          Don't show me again
        </Button>
      </DialogActions>
    </Dialog>
  );
}
