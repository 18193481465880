import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const InProgressCourses = ({ allCoursesData }) => {
  const user = useSelector((state) => state.user.value);

  const { courses } = user;
  const coursesArray = Object.values(courses);

  //create array of courses enrolled but not completed (aka in progress)
  const inProgress = coursesArray.filter((course) => !course.completed);
  //map to get the names of in progress courses
  const inProgressCourses = inProgress.map((course) => {
    return { courseName: course.courseName, courseId: course.courseId };
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <List component="div" disablePadding>
      {inProgressCourses && inProgressCourses?.length > 0 ? (
        inProgressCourses.map((course) => {
          // Find the course object in allCoursesData
          const courseData =
            allCoursesData &&
            allCoursesData.find((data) => data.courseId === course?.courseId);

          return (
            <ListItem key={course?.courseName}>
              <ListItemAvatar>
                <Avatar
                  src={courseData ? courseData.imageURL : ""}
                  alt={course.courseName}
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: "4px",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={course?.courseName}
                onClick={() =>
                  course?.courseId && navigate(`/courses/${course?.courseId}`)
                }
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 1,
                  mb: 1,
                  mx: 2,
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              />
            </ListItem>
          );
        })
      ) : (
        <ListItem>
          <Typography variant="body2" color="textSecondary">
            {t("coursesPreview.noCoursesInProgress")}
          </Typography>
        </ListItem>
      )}
    </List>
  );
};

export default InProgressCourses;
