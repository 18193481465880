import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import CertificateDialog from "../CertificateDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QuizReviewModal from "../QuizReviewModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SchoolIcon from "@mui/icons-material/School";

const CompletedCourses = ({
  userObject,
  allCoursesData,
  multiCreditCourseDetails,
}) => {
  const [anchorEl, setAnchorEl] = useState({});
  const [openQuizReview, setOpenQuizReview] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [openCertModal, setOpenCertModal] = useState(false);
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.value);

  const { courses } = user;
  const coursesArray = Object.values(courses);

  //filter for courses that were completed
  const completedCourses = coursesArray.filter(
    (course) => course.completed && course.courseId !== "tutorial"
  );

  const handleDropdownOpen = (event, id) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleDropdownClose = () => {
    setAnchorEl({});
  };

  const handleCoursesCertificate = (course) => {
    setSelectedCourse(course);
    setOpenCertModal(true);
  };

  const closeCertificateModal = () => {
    setOpenCertModal(false);
  };

  return (
    <>
      <QuizReviewModal
        openQuizReview={openQuizReview}
        setOpenQuizReview={setOpenQuizReview}
        courses={allCoursesData}
        selectedCourse={selectedCourse}
      />

      {/* Certificate dialog */}
      <CertificateDialog
        openCertModal={openCertModal}
        closeCertificateModal={closeCertificateModal}
        userObject={userObject}
        course={selectedCourse}
      />

      <List
        component="nav"
        aria-label="completed courses"
        sx={{ width: "100%", overflowY: "auto" }}
      >
        {/* Render completed courses */}
        {completedCourses.length > 0 ? (
          completedCourses.map((course) => {
            const courseData = allCoursesData.find(
              (data) => data.courseId === course.courseId
            );
            return (
              <ListItem
                key={course.courseId}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  mb: 1,
                  overflow: "hidden",
                }}
              >
                {/* Certificate icon */}
                <SchoolIcon
                  sx={{ width: 40, height: 40, color: "action.active" }} //make colour gray
                />
                <ListItemText
                  primary={course.courseName}
                  primaryTypographyProps={{
                    variant: "subtitle1",
                    noWrap: true,
                    sx: {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      mx: 2,
                    },
                  }}
                />
                <IconButton
                  aria-label="more"
                  aria-controls={`course-menu-${course.courseId}`}
                  aria-haspopup="true"
                  onClick={(e) => handleDropdownOpen(e, course.courseId)}
                  edge="end"
                  sx={{ ml: 1 }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id={`course-menu-${course.courseId}`}
                  keepMounted
                  anchorEl={anchorEl[course.courseId]}
                  open={Boolean(anchorEl[course.courseId])}
                  onClose={handleDropdownClose}
                >
                  <MenuItem onClick={() => handleCoursesCertificate(course)}>
                    Certificate
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSelectedCourse(course);
                      setOpenQuizReview(true);
                      handleDropdownClose();
                    }}
                  >
                    {t("reviewQuiz.reviewTitle")}
                  </MenuItem>
                </Menu>
              </ListItem>
            );
          })
        ) : (
          <ListItem>
            <Typography variant="body2" color="textSecondary" sx={{ my: 2 }}>
              {t("coursesPreview.noCompletedCourses")}
            </Typography>
          </ListItem>
        )}

        {/* Render multi-credit sections */}
        {multiCreditCourseDetails.map((course) =>
          course.completedSections.map((section) => (
            <ListItem
              key={`${course.courseId}-section-${section.sectionId}`}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                bgcolor: "background.paper",
                borderRadius: 1,
                mb: 1,
                overflow: "hidden",
              }}
            >
              {/* Certificate icon */}
              <SchoolIcon
                sx={{ width: 40, height: 40, color: "action.active" }} //make colour gray
              />
              <ListItemText
                primary={`${section.moduleName} (${section.creditsEarned})`}
                secondary={`Completed on: ${new Date(
                  section.date
                ).toLocaleDateString()}`}
                primaryTypographyProps={{
                  variant: "subtitle1",
                  noWrap: true,
                  sx: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    mx: 2,
                  },
                }}
                secondaryTypographyProps={{
                  sx: {
                    marginLeft: 2,
                  },
                }}
              />
              <IconButton
                aria-label="more"
                aria-controls={`module-menu-${course.courseId}-${section.sectionId}`}
                aria-haspopup="true"
                onClick={(e) =>
                  handleDropdownOpen(
                    e,
                    `${course.courseId}-${section.sectionId}`
                  )
                }
                edge="end"
                sx={{ ml: 1 }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`module-menu-${course.courseId}-${section.sectionId}`}
                keepMounted
                anchorEl={anchorEl[`${course.courseId}-${section.sectionId}`]}
                open={Boolean(
                  anchorEl[`${course.courseId}-${section.sectionId}`]
                )}
                onClose={handleDropdownClose}
              >
                <MenuItem
                  onClick={() =>
                    handleCoursesCertificate({
                      ...section,
                      courseName: course.courseName,
                      moduleName: section?.moduleName,
                      courseId: `${course.courseId}-section-${section.sectionId}`,
                    })
                  }
                >
                  Certificate
                </MenuItem>
              </Menu>
            </ListItem>
          ))
        )}
      </List>
    </>
  );
};

export default CompletedCourses;
