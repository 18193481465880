import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Card, Typography, Button, Box, CssBaseline } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { linkPharmacy } from "../helpers/source";
import PharmacyAutocompleteDialog from "./PharmacyAutocomplete";
import ReauthenticateUser from "./ReauthenticateUser";
import "./user-profile-page.scss";
import LinkedPharmacies from "./user-profile-sections/LinkedPharmacies";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageTitleHeader from "../PageTitleHeader";
import YourCourseListToggle from "./YourCourseListToggle";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AccountSettingsModal from "./AccountSettingModal";
import { useSelector } from "react-redux";
import { getCurrentUserObject } from "../helpers/source";

export default function UserProfilePage() {
  const user = useSelector((state) => state.user.value);

  //will use location to check if there's a navigation state
  const location = useLocation();
  const { currentUser, deleteUser } = useAuth();

  const navigate = useNavigate();
  const [userObject, setUserObject] = useState(null);
  const [completedCourses, setCompletedCourses] = useState([]);
  const [inProgressCourses, setInProgressCourses] = useState([]);
  const [openAddPharmacyModal, setOpenAddPharmacyModal] = useState(false);
  const [openReauthenticate, setOpenReauthenticate] = useState(false);
  const [multiCreditCourseDetails, setMultiCreditCourseDetails] = useState([]);

  const [requestOpenSettingsModalForPin, setRequestOpenSettingsModalForPin] =
    useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getCurrentUserObject(currentUser.uid).then((res) => {
      setUserObject(res);
    });
  }, []);
  useEffect(() => {
    if (location.state?.promptPinSetup) {
      setRequestOpenSettingsModalForPin(true);
      // clear the state to prevent it affecting future renders
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  const CourseSummaryCard = styled(Card)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    border: 0,
    borderRadius: "1rem",
    boxShadow: "none",
    padding: "2rem",
    flex: 1,
    marginRight: "1rem",

    [theme.breakpoints.down("md")]: {
      margin: "1em",
    },
  }));

  useEffect(() => {
    // If the user has completed any courses, set completedCourses state to an array of completed course objects
    if (userObject?.courses) {
      //return array individual course objects
      const coursesArray = Object.values(userObject.courses);

      //filter for courses that were completed
      const completed = coursesArray.filter(
        (course) => course.completed && course.courseId !== "tutorial"
      );
      //create array of courses enrolled but not completed (aka in progress)
      const inProgress = coursesArray.filter((course) => !course.completed);
      //map to get the names of in progress courses
      const inProgressCourses = inProgress.map((course) => {
        return { courseName: course.courseName, courseId: course.courseId };
      });

      // go thru multi-credit courses
      const multiCreditDetails = coursesArray
        .filter((course) => course.multipleCreditCourse)
        .map((course) => ({
          courseId: course.courseId,
          courseName: course.courseName,
          completedSections: Object.entries(course.completedSections || {}).map(
            ([sectionId, details]) => ({
              sectionId,
              ...details,
            })
          ),
        }));

      setMultiCreditCourseDetails(multiCreditDetails);
      setCompletedCourses(completed);
      setInProgressCourses(inProgressCourses);
    }
  }, [userObject]);

  // function called when user links a pharmacy, add it to user in db
  const handleAddPharmacy = (selectedPharmacy) => {
    const { pharmacyId, pharmacyName, pharmacyPhone } = selectedPharmacy;
    const pharmacyObject = {
      pharmacyId,
      pharmacyName,
      pharmacyPhone,
    };
    linkPharmacy(userObject.uid, pharmacyObject)
      .then(() => {
        setOpenAddPharmacyModal(false);
        setUserObject((prevUserObject) => ({
          ...prevUserObject,
          linkedPharmacies: [
            ...(prevUserObject.linkedPharmacies || []),
            pharmacyObject,
          ],
        }));
      })
      .catch((error) => {
        console.error("Error adding pharmacy", error);
      });
  };

  // Function called after reauthentication to delete user account
  const handleDeleteAfterReauth = (email, password) => {
    deleteUser(email, password).then(() => navigate("/account-deleted"));
  };

  //user needs to reauthenticate password to delete acct
  const handleCloseReauthenticate = () => {
    setOpenReauthenticate(false);
  };

  const renderLinkPharmacyButton = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 1,
      }}
    >
      <Typography variant="body2" color="textSecondary" align="center">
        Linking your Education account with your pharmacy's Medessist account
        will allow the pharmacy to recognize the courses you have completed and
        may open up more functionality for you.
      </Typography>
      <Button
        onClick={() => setOpenAddPharmacyModal(true)}
        startIcon={<AddIcon />}
      >
        Link A Pharmacy
      </Button>
    </Box>
  );

  return (
    <>
      <PageTitleHeader pageTitle={t("nav.profile")} />
      <CssBaseline />

      {/* hold two white cards side by side, courses vs pharmacies linked */}
      <div className="user-courses-pharmacies">
        <AccountSettingsModal
          setOpenReauthenticate={setOpenReauthenticate}
          userObject={userObject}
        />
        <CourseSummaryCard
          sx={{
            display: "flex",
            flexDirection: "column",
            border: 0,
            borderRadius: "1rem",
            boxShadow: "none",
            padding: "2rem",
            flex: 1,
            marginRight: "1rem",
            marginLeft: "1rem",
          }}
        >
          <YourCourseListToggle
            completedCourses={completedCourses}
            inProgressCourses={inProgressCourses}
            multiCreditCourseDetails={multiCreditCourseDetails}
            userObject={userObject}
          />
        </CourseSummaryCard>
        {/* only show linked pharmacies card if user is a pharmacist */}
      </div>
      {user?.profession === "Pharmacist" && (
        <div className="linked-pharmacies">
          {/* Linked Pharmacies */}
          <LinkedPharmacies setOpenAddPharmacyModal={setOpenAddPharmacyModal} />
        </div>
      )}
      {/* Reauthenticate user before account deletion */}
      <ReauthenticateUser
        open={openReauthenticate}
        onClose={handleCloseReauthenticate}
        handleDeleteAfterReauth={handleDeleteAfterReauth}
      />
      {/* "Add a pharmacy" modal with autocompleting form */}
      <PharmacyAutocompleteDialog
        open={openAddPharmacyModal}
        setOpen={setOpenAddPharmacyModal}
        handleAddPharmacy={handleAddPharmacy}
        userId={currentUser.uid}
      />
    </>
  );
}
