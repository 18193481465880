import React, { useEffect, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  linkPharmacy,
  deletePharmacyLinkRequest,
  getCurrentPIN,
} from "../components/helpers/source";
import { addUserPharmacy } from "../features/states/linkedPharmaciesSlice";
import { removePendingPharmacyRequestSliced } from "../features/states/pendingPharmacyRequestsSlice";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { getUpdatingPendingPharmacyRequests } from "../features/states/pendingPharmacyRequestsSlice";
import { useAuth } from "../contexts/AuthContext";
import { getUpdatingMyPin } from "../features/states/myPinSlice";
import { Snackbar, Alert } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import PendingRequestPinChangeEntry from "./PendingRequestPinChangeEntry";

export default function PendingRequestButtonAndModal({ open, setOpen }) {
  const queryClient = useQueryClient();
  queryClient.invalidateQueries();
  const { currentUser } = useAuth();
  const pendingPharmacyRequests = useSelector(
    (state) => state.pendingPharmacyRequests.value
  );
  const myPin = useSelector((state) => state.myPin.value);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser) {
      dispatch(getUpdatingPendingPharmacyRequests(currentUser.uid));
      dispatch(getUpdatingMyPin(currentUser.uid));
    }
  }, [dispatch, open]);

  const handleAcceptRequest = async (pharmacyObject) => {
    await linkPharmacy(currentUser.uid, pharmacyObject)
      .then(() => {
        dispatch(addUserPharmacy(pharmacyObject));
      })
      .catch((error) => {
        console.error("Error adding pharmacy", error);
      });
  };
  const handleDeleteRequest = async (pharmacyId) => {
    await deletePharmacyLinkRequest(currentUser.uid, pharmacyId)
      .then(() => {
        dispatch(removePendingPharmacyRequestSliced(pharmacyId));
      })
      .catch((error) => {
        console.error("Error deleting request", error);
      });
  };

  const [toastOpen, setToastOpen] = useState(false);

  const databasePIN = useQuery({
    queryKey: [myPin],
    queryFn: async () => await getCurrentPIN(currentUser.uid),
  });

  return (
    <>
      <Dialog
        onClose={(event, reason) => {
          if (
            (reason !== "backdropClick" && reason !== "escapeKeyDown") ||
            databasePIN.data
          ) {
            setOpen(false);
          }
        }}
        open={open}
        // onClose={() => setOpen(false)}
      >
        <DialogTitle>MedEssist Dashboard Link Requests</DialogTitle>
        <List>
          <Snackbar
            open={toastOpen}
            autoHideDuration={6000}
            onClose={() => setToastOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={() => setToastOpen(false)} severity="success">
              Pin Saved! Return to the MedEssist Dashboard to sign in with your
              PIN
            </Alert>
          </Snackbar>

          <>
            {pendingPharmacyRequests?.length > 0 ? (
              <>
                {!databasePIN.data && (
                  <PendingRequestPinChangeEntry
                    toastOpen={toastOpen}
                    setToastOpen={setToastOpen}
                  />
                )}

                {databasePIN.data &&
                  pendingPharmacyRequests?.map((request) => {
                    return (
                      <ListItem
                        key={request.pharmacyId}
                        secondaryAction={
                          <>
                            <IconButton
                              onClick={async () => {
                                await handleAcceptRequest(request).then(() =>
                                  handleDeleteRequest(request.pharmacyId)
                                );
                              }}
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                handleDeleteRequest(request.pharmacyId);
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          </>
                        }
                      >
                        <ListItemText
                          primary={request.pharmacyName}
                          secondary={request.pharmacyPhone}
                        />
                      </ListItem>
                    );
                  })}
              </>
            ) : (
              <ListItem>
                <ListItemText primary={"No current requests"} />
              </ListItem>
            )}
          </>
        </List>
      </Dialog>
    </>
  );
}
