import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignInOutContainer from "./components/containers/SignInOutContainer";
import ForgotPassword from "./components/auth/ForgotPassword";
import Dashboard from "./components/Dashboard";
import AccountDeleted from "./components/user-profiles/AccountDeleted";
import UserProfilePage from "./components/user-profiles/UserProfilePage";
import "./components/auth/auth.scss";
import { AuthProvider } from "./contexts/AuthContext";
import { UserProvider } from "./contexts/UserProvider";
import PrivateRoute from "./components/PrivateRoute";
import TopNavBar from "./components/navbars/TopNavBar";
import CourseFromDB from "./components/courses/CourseFromDB";
import "./App.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Referral from "./components/referral/Referral";
import Footer from "./components/navbars/Footer";
import ContactUs from "./components/contactus/ContactUs";
import PoweredBy from "./components/PoweredBy";
import SuggestCourse from "./components/suggest-course/SuggestCourse";
import TeachOnMedEssist from "./components/teach/Teach";
import Blog from "./components/blog/Blog";
import Admin from "./components/admin/Admin";
import Resources from "./components/resources/Resources";
import "./i18n";
import LoggedInHeader from "./components/LoggedInHeader";
import Homepage from "./components/Homepage";
import NotFound from "./components/notFound/NotFound";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PinChange from "./components/pin/PinChange";
import AccountSettingsModal from "./components/user-profiles/AccountSettingModal";
const theme = createTheme({
  typography: {
    fontFamily: `"Nunito Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});
const queryClient = new QueryClient();
function App() {
  const [openAI, setOpenAI] = useState(null);

  const handleOpenAIButton = () => {
    if (openAI === null) {
      setOpenAI(true);
    } else {
      setOpenAI(null);
    }
  };

  return (
    <>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={false} />  */}
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <UserProvider>
                <BrowserRouter>
                  <div
                    style={{ minHeight: "calc(100vh - 194px)" }}
                    className="app-wrapper"
                  >
                    <TopNavBar />

                    <div className="main-wrapper">
                      <LoggedInHeader />
                      <Routes>
                        <Route
                          path="/login"
                          element={<SignInOutContainer />}
                        ></Route>
                        <Route
                          path="/passwordreset"
                          element={<ForgotPassword />}
                        ></Route>
                        <Route
                          exact
                          path="/"
                          element={
                            <Homepage handleOpenAIButton={handleOpenAIButton} />
                          }
                        ></Route>
                        <Route
                          exact
                          path="/courses"
                          element={<Dashboard />}
                        ></Route>
                        <Route
                          exact
                          path="/profile"
                          element={
                            <PrivateRoute>
                              <UserProfilePage />
                            </PrivateRoute>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/referral"
                          element={
                            <PrivateRoute>
                              <Referral />
                            </PrivateRoute>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/pinChange"
                          element={
                            <PrivateRoute>
                              <PinChange />
                            </PrivateRoute>
                          }
                        ></Route>
                        <Route
                          exact
                          path="/about"
                          element={<ContactUs />}
                        ></Route>
                        <Route
                          path="/courses/:courseId"
                          element={<CourseFromDB />}
                        ></Route>
                        <Route path="/admin" element={<Admin />}></Route>
                        <Route
                          path="/account-deleted"
                          element={<AccountDeleted />}
                        />
                        <Route path="/teach" element={<TeachOnMedEssist />} />
                        <Route exact path="/blog" element={<Blog />} />
                        <Route
                          exact
                          path="/suggest-course"
                          element={<SuggestCourse />}
                        />
                        <Route
                          exact
                          path="/resources"
                          element={
                            <PrivateRoute>
                              <Resources />
                            </PrivateRoute>
                          }
                        />

                        <Route exact element={<NotFound />} path="*" />
                      </Routes>
                      <PoweredBy />
                    </div>
                  </div>
                  <Footer
                    handleOpenAIButton={handleOpenAIButton}
                    openAI={openAI}
                  />
                </BrowserRouter>
              </UserProvider>
            </AuthProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </div>
    </>
  );
}
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<App />);
export default App;
